(function () {
	'use strict';

	angular
		.module('app')
		.filter('html', function ($sce) {
			return function (content) {
				return $sce.trustAsHtml(content);
			};
		})
		.filter('momentDate', function ($sce) {
			return function (input, settings) {
				var format = settings.dateStringFormat;
				return moment(input).format(format);
			};
		})
		.filter('momentStart', function ($sce) {
			return function (input, settings, isAllDay) {
				if (!input) {
					return $sce.trustAsHtml(date);
				}
				var date = isAllDay
					? moment(input).format(settings.dateStringFormat)
					: moment(input).format(settings.dateStringFormat) +
					  '&nbsp;&nbsp;&nbsp;' +
					  '<span class="time">' +
					  moment(input).format(settings.timeFormat) +
					  '</span>';
				return $sce.trustAsHtml(date);
			};
		})
		.filter('momentTimeDuration', function (utilities) {
			return function (input, compareMoment, durationLabel) {
				var diff;
				var result;
				var label;

				if (input.isBefore(compareMoment)) {
					diff = compareMoment.preciseDiff(input);
					label = durationLabel === 'time' ? 'ago' : durationLabel;
					result = diff + ' ' + label;
				} else {
					diff = input.preciseDiff(compareMoment);
					if (durationLabel === 'time') {
						result = result = 'in ' + diff;
					} else {
						result = diff + ' ' + durationLabel;
					}
				}

				return result;
			};
		})
		.filter('momentDuration', function (utilities) {
			return function (
				input,
				compareMoment,
				durationFormat,
				durationLabel
			) {
				//durationFormat options: 'day', 'work day'
				//durationLabel options: any string what the duration is related to ie. now, start date, etc.
				input = moment(input).startOf(durationFormat);
				compareMoment = moment(compareMoment).startOf(durationFormat);

				var textDirection;
				var diff;
				var pluralize;
				if (durationLabel === 'now') {
					if (input.isBefore(compareMoment)) {
						diff = compareMoment.diff(input, durationFormat);
						textDirection = 'before';
					} else {
						diff = input.diff(compareMoment, durationFormat);
						textDirection = 'from';
					}
				} else {
					diff = compareMoment.diff(input, durationFormat);
					textDirection = '';
				}

				if (durationFormat === 'work day') {
					diff = utilities.workDays(input, compareMoment);
				}
				pluralize = diff !== 1 ? 's' : '';
				return (
					diff +
					' ' +
					durationFormat +
					pluralize +
					' ' +
					textDirection +
					' ' +
					durationLabel
				);
			};
		})
		.filter('momentDurationPrecise', function () {
			return function (input, isAllDay, compareMoment) {
				var isAnotherDay = input.isAfter(compareMoment, 'days');
				if (isAnotherDay || isAllDay) {
					return;
				}
				return input.preciseDiff(compareMoment);
			};
		})
		.filter('momentEnd', function ($sce, $translate) {
			return function (input, settings, isAllDay, compareMoment) {
				if (!input) {
					return $sce.trustAsHtml('');
				}
				var diff = input.diff(compareMoment);
				var isAnotherDay =
					input.diff(compareMoment, 'days') > 0 ? true : false;
				var endIsBefore = input.isBefore(compareMoment);
				var translations = $translate.instant([
					'minute',
					'minutes',
					'hour',
					'hours',
					'day',
					'days',
				]);
				var date;
				var duration;

				duration =
					!isAnotherDay && !isAllDay && !endIsBefore && diff > 1000
						? input.preciseDiff(compareMoment) +
						  ' &nbsp;&nbsp;&nbsp; '
						: '';

				if (isAllDay) {
					date = moment(input).format(settings.dateStringFormat);
				} else {
					date = isAnotherDay
						? moment(input).format(settings.dateStringFormat) +
						  '&nbsp;&nbsp;&nbsp;' +
						  '<span class="time">' +
						  moment(input).format(settings.timeFormat) +
						  '</span>'
						: '<span class="time">' +
						  moment(input).format(settings.timeFormat) +
						  '</span>';
				}

				//Loop through words in the string and replace them with translated values
				duration = duration.split(' ');

				for (var i = 0; i < duration.length; i++) {
					if (translations[duration[i]]) {
						duration[i] = translations[duration[i]];
					}
				}
				duration = duration.join(' ');

				//Return our new formatted and translated result
				return $sce.trustAsHtml(
					'<span class="edit-label">' + duration + '</span>' + date
				);
			};
		})
		.filter('noEmpties', function ($translate) {
			return function (input) {
				var translation = $translate.instant(['none']);
				return input && input !== 'none' ? input : translation.none;
			};
		})
		.filter('arrayJoin', function () {
			return function (input) {
				if (Array.isArray(input)) {
					return input.join(', ');
				} else {
					return input;
				}
			};
		});
})();
